
export function getSites (data, cb, errorCb) {
  window.axios({
    method: 'get',
    url: 'site?tenantId=' + data.tenantId + '&siteId=' + data.siteId + '&siteName=' + data.siteName
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function getSitesPromise (data) {
  return window.axios({
    method: 'get',
    url: 'site?tenantId=' + data.tenantId + '&siteId=' + data.siteId + '&siteName=' + data.siteName
  })
}

export function getSiteUser (data, cb, errorCb) {
  window.axios({
    method: 'get',
    url: 'site-user?tenantId=' + data.tenantId + '&siteId=' + data.siteId + '&siteName=' + data.siteName + '&offset=' + data.offset + '&limit=' + data.limit
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function postSiteUser (data, cb, errorCb) {
  window.axios({
    method: 'post',
    url: 'site-user',
    data: data
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function patchSiteUser (param, data, cb, errorCb) {
  // site-user?id=wantanto@scg.com&_etag="32002929-0000-1800-0000-605840370000"&tenantId=cbe05f1a-ed20-430c-aee9-b591f3774f1f&siteId=28c7fd0d-cc71-4db7-a819-0246df42e7cf
  window.axios({
    method: 'patch',
    url: 'site-user?id=' + param.id + '&_etag=' + param.etag + '&tenantId=' + param.tenantId + '&siteId=' + param.siteId,
    data: data
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function getTenant (data, cb, errorCb) {
  window.axios({
    method: 'get',
    url: 'tenant?tenantId=' + data.tenantId + '&tenantName=' + data.tenantName
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function postTenant (data, cb, errorCb) {
  window.axios({
    method: 'post',
    url: 'tenant',
    data: data
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function patchTenant (param, data, cb, errorCb) {
  window.axios({
    method: 'patch',
    url: 'tenant?id=' + param.id + '&_etag=' + param.etag,
    data: data
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function getForm (data, cb, errorCb) {
  window.axios({
    method: 'get',
    url: 'form?tenantId=' + data.tenantId + '&siteId=' + data.siteId + '&offset=' + data.offset + '&limit=' + data.limit + '&' + data.formTypeId + '&' + data.year + '&' + data.active
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function getFormPromise (data) {
  return window.axios({
    method: 'get',
    url: 'form?tenantId=' + data.tenantId + '&siteId=' + data.siteId + '&offset=' + data.offset + '&limit=' + data.limit + '&' + data.formTypeId + '&' + data.year + '&' + data.active
  })
}

export function postSite (data, cb, errorCb) {
  window.axios({
    method: 'post',
    url: 'site',
    data: data
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function getCollector (data, cb, errorCb) {
  window.axios({
    method: 'get',
    url: 'site-data-collector?tenantId=' + data.tenantId + '&siteId=' + data.siteId + '&offset=' + data.offset + '&limit=' + data.limit
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function postCollector (data, cb, errorCb) {
  window.axios({
    method: 'post',
    url: 'site-data-collector',
    data: data
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function patchCollector (param, data, cb, errorCb) {
  window.axios({
    method: 'patch',
    url: 'site-data-collector?id=' + param.id + '&_etag=' + param.etag + '&tenantId=' + param.tenantId + '&siteId=' + param.siteId,
    data: data
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function getMasterRole (data, cb, errorCb) {
  window.axios({
    method: 'get',
    url: 'master-role?keyword=' + data.keyword
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function getMasterFormType (data) {
  return window.axios({
    method: 'get',
    url: 'master-form-type?keyword=' + data.keyword
  })
}

export function createForm (data, cb, errorCb) {
  window.axios({
    method: 'post',
    url: 'form',
    data: data
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function getYear () {
  return window.axios({
    method: 'get',
    url: 'master-year'
  })
}

export function getHealthProvider (data) {
  return window.axios({
    method: 'get',
    url: 'master-health-provider?healthProviderCode9=' + data.keyword
  })
}

export function getAreaHealth () {
  // master-address-areahealth
  return window.axios({
    method: 'get',
    url: 'master-address-areahealth'
  })
}

export function getProvince (data) {
  // master-address-province?areaHealth=13
  return window.axios({
    method: 'get',
    url: 'master-address-province?areaHealth=' + data.areaHealth
  })
}

export function getProviderType () {
  // master-health-provider-type
  return window.axios({
    method: 'get',
    url: 'master-health-provider-type'
  })
}

export function getDistrict (data) {
  return window.axios({
    method: 'get',
    url: 'master-address-district?province=' + data.province
  })
}

export function getSubDistrict (data) {
  return window.axios({
    method: 'get',
    url: 'master-address-subdistrict?district=' + data.district
  })
}

export function getMinistry () {
  return window.axios({
    method: 'get',
    url: 'master-ministry'
  })
}

export function getDepartment () {
  return window.axios({
    method: 'get',
    url: 'master-department'
  })
}

export function getFormResult (data, cb, errorCb) {
  window.axios({
    method: 'get',
    url: 'form-result?tenantId=' + data.tenantId + '&siteId=' + data.siteId + '&formTypeId=' + data.formTypeId + '&' + data.year + '&offset=' + data.offset + '&limit=' + data.limit + '&sortBy=' + data.sortBy + '&sortDesc=' + data.sortDesc + '&' + data.formId + '&startDate=' + data.startDate + '&endDate=' + data.endDate + '&keyword=' + data.keyword
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function getFormResultItem (param, cb, errorCb) {
  window.axios({
    method: 'get',
    url: 'form-result?tenantId=' + param.tenantId + '&siteId=' + param.siteId + '&formTypeId=' + param.formTypeId + '&id=' + param.id
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function patchFormResult (param, data, cb, errorCb) {
  window.axios({
    method: 'patch',
    url: 'form-result?tenantId=' + param.tenantId + '&siteId=' + param.siteId + '&formTypeId=' + param.formTypeId + '&id=' + param.id + '&_etag=' + param.etag,
    data: data
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function postFormResult (data, cb, errorCb) {
  window.axios({
    method: 'post',
    url: 'form-result',
    data: data
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function deleteFormResult (param, cb, errorCb) {
  window.axios({
    method: 'delete',
    url: 'form-result?tenantId=' + param.tenantId + '&siteId=' + param.siteId + '&formTypeId=' + param.formTypeId + '&id=' + param.id
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function getContentType (data, cb, errorCb) {
  // content?contentType=privacy_policy
  window.axios({
    method: 'get',
    url: 'content?contentType=' + data.contentType
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function postConsent (data, cb, errorCb) {
  window.axios({
    method: 'post',
    url: 'consent',
    data: data
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function getSiteFormType (data) {
  return window.axios({
    method: 'get',
    url: 'site-form-type?tenantId=' + data.tenantId + '&siteId=' + data.siteId + '&offset=' + data.offset + '&limit=' + data.limit + '&sortBy=' + data.sortBy + '&sortDesc=' + data.sortDesc
  })
}

export function postSiteFormType (data, cb, errorCb) {
  window.axios({
    method: 'post',
    url: 'site-form-type',
    data: data
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function postMasterFormType (data, cb, errorCb) {
  window.axios({
    method: 'post',
    url: 'master-form-type',
    data: data
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function patchMasterFormType (param, data, cb, errorCb) {
  window.axios({
    method: 'patch',
    url: 'master-form-type?id=' + param.id + '&_etag=' + param.etag,
    data: data
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function updateSiteFormType (param, data, cb, errorCb) {
  window.axios({
    method: 'patch',
    url: 'site-form-type?id=' + param.id + '&_etag=' + param.etag + '&tenantId=' + param.tenantId + '&siteId=' + param.siteId,
    data: data
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function deleteSiteFormType (param, cb, errorCb) {
  window.axios({
    method: 'delete',
    url: 'site-form-type?id=' + param.id + '&_etag=' + param.etag + '&tenantId=' + param.tenantId + '&siteId=' + param.siteId
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function postForm (data, cb, errorCb) {
  window.axios({
    method: 'post',
    url: 'form',
    data: data
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function patchForm (param, data, cb, errorCb) {
  window.axios({
    method: 'patch',
    url: 'form?tenantId=' + param.tenantId + '&siteId=' + param.siteId + '&id=' + param.id + '&_etag=' + param.etag,
    data: data
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
  // form?tenantId=cbe05f1a-ed20-430c-aee9-b591f3774f1f&siteId=e9c7075f-b5c1-493d-af2f-eb935fa36aee&id=4dbe9d59-c44f-4c8e-a35e-6c4448251e4a&_etag="2400bdb3-0000-1800-0000-6054624a0000"
}

export function patchSite (param, data, cb, errorCb) {
  window.axios({
    method: 'patch',
    url: 'site?tenantId=' + param.tenantId + '&siteId=' + param.siteId + '&id=' + param.id + '&_etag=' + param.etag,
    data: data
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function patchSitePromise (param, data) {
  return window.axios({
    method: 'patch',
    url: 'site?tenantId=' + param.tenantId + '&siteId=' + param.siteId + '&id=' + param.id + '&_etag=' + param.etag,
    data: data
  })
}

export function getSiteTarget (data, cb, errorCb) {
  window.axios({
    method: 'get',
    url: 'site-target?tenantId=' + data.tenantId + '&siteId=' + data.siteId + '&offset=' + data.offset + '&limit=' + data.limit + '&sortBy=&sortDesc=0'
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function postSiteTarget (data, cb, errorCb) {
  window.axios({
    method: 'post',
    url: 'site-target',
    data: data
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function patchSiteTarget (param, data, cb, errorCb) {
  window.axios({
    method: 'patch',
    url: 'site-target?tenantId=' + param.tenantId + '&siteId=' + param.siteId + '&id=' + param.id + '&_etag=' + param.etag,
    data: data
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function deleteSiteTarget (param, cb, errorCb) {
  window.axios({
    method: 'delete',
    url: 'site-target?tenantId=' + param.tenantId + '&siteId=' + param.siteId + '&id=' + param.id + '&_etag=' + param.etag
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function deleteSiteCollector (param, cb, errorCb) {
  window.axios({
    method: 'delete',
    url: 'site-data-collector?tenantId=' + param.tenantId + '&siteId=' + param.siteId + '&id=' + param.id + '&_etag=' + param.etag
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function getReportTopScreening (data, cb, errorCb) {
  //  'https://vwell-screening-web-dev.azurewebsites.net/api/report-topscreening?tenantId=cbe05f1a-ed20-430c-aee9-b591f3774f1f&siteId=e9c7075f-b5c1-493d-af2f-eb935fa36aee'
  window.axios({
    method: 'get',
    url: 'report-topscreening?tenantId=' + data.tenantId + '&siteId=' + data.siteId
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function getReportTopSelfReport (data, cb, errorCb) {
  window.axios({
    method: 'get',
    url: 'report-topselfreport?tenantId=' + data.tenantId + '&siteId=' + data.siteId
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function postTenantAdmin (data, cb, errorCb) {
  window.axios({
    method: 'post',
    url: 'tenant-admin',
    data: data
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function getPatient (data, cb, errorCb) {
  window.axios({
    method: 'get',
    url: 'patient?tenantId=' + data.tenantId + '&siteId=' + data.siteId + '&offset=' + data.offset + '&limit=' + data.limit + '&sortBy=' + data.sortBy + '&sortDesc=' + data.sortDesc + '&keyword=' + data.keyword
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function getPatientToken (param, cb, errorCb) {
  window.axios({
    method: 'get',
    url: 'patient-token?tenantId=' + param.tenantId + '&siteId=' + param.siteId + '&cid=' + param.cid + '&formId=' + param.formId
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function getPatientTokenAwait (param) {
  return window.axios({
    method: 'get',
    url: 'patient-token?tenantId=' + param.tenantId + '&siteId=' + param.siteId + '&cid=' + param.cid + '&formId=' + param.formId
  })
}

export function postPatient (data, cb, errorCb) {
  window.axios({
    method: 'post',
    url: 'patient',
    data: data
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function patchPatient (param, data, cb, errorCb) {
  window.axios({
    method: 'patch',
    url: 'patient?tenantId=' + param.tenantId + '&siteId=' + param.siteId + '&id=' + param.id + '&_etag=' + param.etag,
    data: data
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function getGroup (data) {
  return window.axios({
    method: 'get',
    url: 'group?tenantId=' + data.tenantId + '&siteId=' + data.siteId
  })
}

export function postGroup (data, cb, errorCb) {
  window.axios({
    method: 'post',
    url: 'group',
    data: data
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function patchGroup (param, data, cb, errorCb) {
  window.axios({
    method: 'patch',
    url: 'group?tenantId=' + param.tenantId + '&siteId=' + param.siteId + '&id=' + param.id + '&_etag=' + param.etag,
    data: data
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function getMonitor (data, cb, errorCb) {
  window.axios({
    method: 'get',
    url: 'monitor-form?tenantId=' + data.tenantId + '&siteId=' + data.siteId + '&groupId=' + data.groupId + '&formTypeId=' + data.formTypeId + '&formId=' + data.formId + '&startDate=' + data.startDate + '&endDate=' + data.endDate + '&keyword=' + data.keyword
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function getMonitorHistory (data, cb, errorCb) {
  window.axios({
    method: 'get',
    url: 'monitor-form-history?tenantId=' + data.tenantId + '&siteId=' + data.siteId + '&cid=' + data.cid + '&formTypeId=' + data.formTypeId + '&formId=' + data.formId + '&offset=' + data.offset + '&limit=' + data.limit
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function getGroupPatient (param, cb, errorCb) {
  window.axios({
    method: 'get',
    url: 'group-patient?tenantId=' + param.tenantId + '&siteId=' + param.siteId + '&groupId=' + param.groupId
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function postGroupPatient (data, cb, errorCb) {
  window.axios({
    method: 'post',
    url: 'group-patient',
    data: data
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function deleteGroupPatient (param, cb, errorCb) {
  window.axios({
    method: 'delete',
    url: 'group-patient?tenantId=' + param.tenantId + '&siteId=' + param.siteId + '&id=' + param.id
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function getAnnouncement (param, cb, errorCb) {
  window.axios({
    method: 'get',
    url: 'announcement?tenantId=' + param.tenantId + '&siteId=' + param.siteId
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function postAnnouncement (data, cb, errorCb) {
  window.axios({
    method: 'post',
    url: 'announcement',
    data: data
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function deleteAnnouncement (param, cb, errorCb) {
  window.axios({
    method: 'delete',
    url: 'announcement?tenantId=' + param.tenantId + '&siteId=' + param.siteId + '&id=' + param.id
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function patchAnnouncement (param, data, cb, errorCb) {
  window.axios({
    method: 'patch',
    url: 'announcement?tenantId=' + param.tenantId + '&siteId=' + param.siteId + '&id=' + param.id + '&_etag=' + param.etag,
    data: data
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function getAnnouncementBanner (param, cb, errorCb) {
  window.axios({
    method: 'get',
    url: 'announcement-banner?tenantId=' + param.tenantId + '&siteId=' + param.siteId + '&bannerName=' + param.bannerName
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function postAnnouncementBanner (data, cb, errorCb) {
  window.axios({
    method: 'post',
    url: 'announcement-banner',
    data: data
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function getSiteContentType (param, cb, errorCb) {
  window.axios({
    method: 'get',
    url: 'site-content?tenantId=' + param.tenantId + '&siteId=' + param.siteId // + '&contentType=' + param.contentType
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function postSiteContentType (data, cb, errorCb) {
  window.axios({
    method: 'post',
    url: 'site-content',
    data: data
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function getTokenComService () {
  return window.axios({
    method: 'post',
    url: 'token-comservice'
  })
}

export function getMasterNewsReceiver (param) {
  return window.axios({
    method: 'get',
    url: 'master-news-receiver?tenantId=' + param.tenantId + '&siteId=' + param.siteId
  })
}

export function getMasterNewsType (param) {
  return window.axios({
    method: 'get',
    url: 'master-news-type?tenantId=' + param.tenantId + '&siteId=' + param.siteId
  })
}

export function postNews (data, cb, errorCb) {
  window.axios({
    method: 'post',
    url: 'news',
    data: data
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function getNews (param) {
  return window.axios({
    method: 'get',
    url: 'news?tenantId=' + param.tenantId + '&siteId=' + param.siteId + '&offset=' + param.offset + '&limit=' + param.limit + '&newsReceiver=' + param.newsReceiver + '&groupId=' + param.groupId + '&newsType=' + param.newsType
  })
}

export function patchNews (param, data, cb, errorCb) {
  window.axios({
    method: 'patch',
    url: 'news?id=' + param.id + '&_etag=' + param.etag,
    data: data
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function deleteNews (param, cb, errorCb) {
  window.axios({
    method: 'delete',
    url: 'news?id=' + param.id
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function postNewsFile (data, cb, errorCb) {
  window.axios({
    method: 'post',
    url: 'news-file',
    data: data
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function getNewsFile (param, cb, errorCb) {
  window.axios({
    method: 'get',
    url: 'news-file?fileName=' + param.fileName
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function getChatThread (param) {
  return window.axios({
    method: 'get',
    url: 'chat-thread?tenantId=' + param.tenantId + '&siteId=' + param.siteId + '&cid=' + param.cid + '&offset=' + param.offset + '&limit=' + param.limit
  })
}

export function postChatThread (data) {
  return window.axios({
    method: 'post',
    url: 'chat-thread',
    data: data
  })
}

export function uploadChatImage (data) {
  return window.axios({
    method: 'post',
    url: 'chat-image',
    data: data
  })
}

export function getFormResultFile (param, cb, errorCb) {
  window.axios({
    method: 'get',
    url: 'form-result-file?tenantId=' + param.tenantId + '&siteId=' + param.siteId + '&formId=' + param.formId + '&fileName=' + param.fileName
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function getBpLevel (param, cb, errorCb) {
  window.axios({
    method: 'get',
    url: `analytic-bplevel?tenantId=${param.tenantId}&siteId=${param.siteId}&formId=${param.formId}&formTypeId=${param.formTypeId}&subdistrict=${param.subdistrict}&startDate=${param.startDate}&endDate=${param.endDate}`
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}
