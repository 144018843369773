import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'
import { getField, updateField } from 'vuex-map-fields'

Vue.use(Vuex)

const persist = new VuexPersistence({
  storage: window.sessionStorage
})

export default new Vuex.Store({
  state: {
    role: null,
    form: {},
    result: {},
    postStatus: {},
    currentSite: {},
    chatThreadLists: [],
    chatClient: null
  },
  mutations: {
    updateField,
    setChatThreadLists (state, data) {
      state.chatThreadLists = data
    },
    setChatClient (state, data) {
      state.chatClient = data
    }
  },
  actions: {
    clearChatThreadLists ({ commit }) {
      commit('setChatThreadLists', [])
    },
    SetChatThreadLists ({ commit }, data) {
      commit('setChatThreadLists', data)
    },
    SetChatClient ({ commit }, data) {
      commit('setChatClient', data)
    }
  },
  modules: {
  },
  getters: {
    getField
  },
  plugins: [persist.plugin]
})
