import Vue from 'vue'
import VueRouter from 'vue-router'
// import NProgress from 'nprogress/nprogress'
// import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Main',
    component: () => import(/* webpackChunkName: "Main" */ '@/layout/Main.vue'),
    children: [
      {
        path: 'overview',
        name: 'general',
        component: () => import(/* webpackChunkName: "General" */ '@/views/General.vue'),
        meta: {
          authRequired: true
        }
      },
      {
        path: 'analytic',
        name: 'analytic',
        component: () => import(/* webpackChunkName: "General" */ '@/views/Analytic.vue'),
        meta: {
          authRequired: true
        }
      },
      {
        path: '/form/list',
        name: 'formlist',
        component: () => import(/* webpackChunkName: "FormList" */ '@/views/FormList.vue'),
        meta: {
          authRequired: true
        }
      },
      {
        path: '/form/result',
        name: 'formresult',
        component: () => import(/* webpackChunkName: "FormResult" */ '@/views/FormResult.vue'),
        meta: {
          authRequired: true
        }
      },
      {
        path: '/form/report',
        name: 'formreport',
        component: () => import(/* webpackChunkName: "FormReport" */ '@/views/FormReport.vue'),
        meta: {
          authRequired: true
        }
      },
      {
        path: '/form/create',
        name: 'formcreate',
        component: () => lazyLoadView(import(/* webpackChunkName: "FormCreate" */ '@/views/FormCreate.vue')),
        meta: {
          authRequired: true
        }
      },
      {
        path: '/form/edit',
        name: 'formedit',
        props: { mode: true },
        component: () => lazyLoadView(import(/* webpackChunkName: "FormCreate" */ '@/views/FormCreate.vue')),
        meta: {
          authRequired: true
        }
      },
      {
        path: '/site',
        name: 'sitesettings',
        component: () => import(/* webpackChunkName: "SiteSettings" */ '@/views/SiteSettings.vue'),
        meta: {
          authRequired: true
        }
      },
      {
        path: '/monitor',
        name: 'monitor',
        component: () => import(/* webpackChunkName: "Monitor" */ '@/views/Monitor.vue'),
        meta: {
          authRequired: true
        }
      },
      {
        path: '/patient',
        name: 'patient',
        component: () => import(/* webpackChunkName: "Patient" */ '@/views/Patient.vue'),
        meta: {
          authRequired: true
        }
      },
      {
        path: '/site/:siteId',
        props: true,
        name: 'siteedit',
        component: () => import(/* webpackChunkName: "SiteSettings" */ '@/views/SiteEdit.vue'),
        meta: {
          authRequired: true
        },
        children: [
          {
            path: 'info',
            name: 'siteinfo',
            component: () => import(/* webpackChunkName: "SiteInfoCard" */ '@/components/site/SiteInfoCard.vue')
          },
          {
            path: 'member',
            name: 'sitemember',
            component: () => import(/* webpackChunkName: "SiteMemberCard" */ '@/components/site/SiteMemberCard.vue')
          },
          {
            path: 'operator',
            name: 'siteoperator',
            component: () => import(/* webpackChunkName: "SiteOperatorCard" */ '@/components/site/SiteOperatorCard.vue')
          },
          {
            path: 'connection',
            name: 'siteconnection',
            component: () => import(/* webpackChunkName: "SiteConnectionCard" */ '@/components/site/SiteConnectionCard.vue')
          },
          {
            path: 'target',
            name: 'sitetarget',
            component: () => import(/* webpackChunkName: "SiteTargetCard" */ '@/components/site/SiteTargetCard.vue')
          },
          {
            path: 'group',
            name: 'group',
            component: () => import(/* webpackChunkName: "SiteGroup" */ '@/components/site/SiteGroupCard.vue')
          },
          {
            path: 'form',
            name: 'siteform',
            component: () => import(/* webpackChunkName: "SiteTargetCard" */ '@/components/site/SiteFormTypeCard.vue')
          },
          {
            path: 'content',
            name: 'sitecontent',
            component: () => import(/* webpackChunkName: "SiteContent" */ '@/components/site/SiteContentCard.vue')
          }
        ]
      },
      {
        path: '/site/create',
        props: true,
        name: 'sitecreate',
        component: () => import(/* webpackChunkName: "SiteSettings" */ '@/views/SiteCreate.vue'),
        meta: {
          authRequired: true
        }
      },
      {
        path: '/tenant',
        name: 'tenant',
        component: () => import(/* webpackChunkName: "Tenant" */ '@/views/TenantSettings.vue'),
        meta: {
          authRequired: true
        }
      },
      {
        path: '/tenant/:tenantId/edit',
        name: 'tenantedit',
        component: () => import(/* webpackChunkName: "TenantEdit" */ '@/views/TenantEdit.vue'),
        meta: {
          authRequired: true
        }
      },
      {
        path: '/report',
        name: 'report',
        component: () => import(/* webpackChunkName: "Report" */ '@/views/Report.vue'),
        meta: {
          authRequired: true
        }
      },
      {
        path: '/analytics',
        name: 'analytics',
        component: () => import(/* webpackChunkName: "Analytics" */ '@/views/Analytics.vue'),
        meta: {
          authRequired: true
        }
      },
      {
        path: '/announcement',
        name: 'announcement',
        component: () => import(/* webpackChunkName: "Announcement" */ '@/views/Announcement.vue'),
        meta: {
          authRequired: true
        }
      }
    ],
    meta: {
      authRequired: true
    }
  },
  {
    path: '/termcondition',
    name: 'termcondition',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "tou" */ '../views/TOU.vue'),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/privacynotice',
    name: 'privacynotice',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "pn" */ '../views/PN.vue'),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/select/tenant',
    name: 'selecttenant',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "selecttenant" */ '../views/SelectTenant.vue'),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/select/site',
    name: 'selectsite',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "selectsite" */ '../views/SelectSite.vue'),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/landing/site',
    name: 'createnewsite',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "selectsite" */ '../views/CreateNewSite.vue'),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/contactus',
    name: 'contactus',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Contactus.vue'),
    meta: {
      authRequired: true
    }
  },
  {
    path: '*',
    name: '404 not found',
    redirect: '/overview'// general
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "pagenotfound" */ '../views/PageNotFound.vue')
  },
  {
    path: '/notauthorized',
    name: 'notauthorized',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "notauthorize" */ '../views/NotAuthorized.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// let isFirstTransition = true
const DEFAULT_TITLE = 'VWELL: สมาร์ท คัดกรอง'
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.authRequired)) {
    if (Vue.prototype.$auth.isAuthenticated) {
      sessionStorage.setItem('LS_ROUTE_KEY', to.name)
      if (to.params) {
        sessionStorage.setItem('LS_ROUTE_PARAMS', JSON.stringify(to.params))
      }
      next()
    } else {
      // next({ name: 'notauthorized' })
    }
  } else {
    next()
  }
  document.title = to.meta.title || DEFAULT_TITLE
})

function lazyLoadView (AsyncView) {
  const AsyncHandler = () => ({
    component: AsyncView,
    // A component to use while the component is loading.
    // loading: require('@views/_loading.vue').default,
    // Delay before showing the loading component.
    // Default: 200 (milliseconds).
    delay: 400,
    // A fallback component in case the timeout is exceeded
    // when loading the component.
    // error: require('@views/_timeout.vue').default,
    // Time before giving up trying to load the component.
    // Default: Infinity (milliseconds).
    timeout: 10000
  })

  return Promise.resolve({
    functional: true,
    render (h, { data, children }) {
      // Transparently pass any props or children
      // to the view component.
      return h(AsyncHandler, data, children)
    }
  })
}

export default router
