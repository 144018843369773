/* eslint-disable quotes */
'use strict'
// import { ChatClient } from '@azure/communication-chat'
// import { AzureCommunicationTokenCredential } from '@azure/communication-common'
import Vue from 'vue'
import * as Msal from '@azure/msal-browser'
import router from '@/router'
import store from '@/store'
import VueRouter from 'vue-router'
import { initChat } from '@/plugins/communication'
const { isNavigationFailure, NavigationFailureType } = VueRouter

// Browser check variables
// If you support IE, our recommendation is that you sign-in using Redirect APIs
// If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
const ua = window.navigator.userAgent
const msie = ua.indexOf("MSIE ")
const msie11 = ua.indexOf("Trident/")
const msedge = ua.indexOf("Edge/")
const isIE = msie > 0 || msie11 > 0
const isEdge = msedge > 0
// import { getSites } from '@/api/'
const b2cPolicies = {
  names: {
    signUpSignIn: 'B2C_1A_SNWEB_signup_signin',
    forgotPassword: 'B2C_1A_SNWEB_PasswordReset',
    editProfile: 'B2C_1A_SNWEB_ProfileEdit'
  },
  authorities: {
    signUpSignIn: {
      authority:
        'https://nextervwell.b2clogin.com/nextervwell.onmicrosoft.com/B2C_1A_SNWEB_signup_signin' // https://nextervwell.b2clogin.com/nextervwell.onmicrosoft.com/B2C_1A_TOU_SUSI //https://fabrikamb2c.b2clogin.com/fabrikamb2c.onmicrosoft.com/B2C_1_susi
    },
    forgotPassword: {
      authority:
        'https://nextervwell.b2clogin.com/nextervwell.onmicrosoft.com/B2C_1A_SNWEB_PasswordReset'
    },
    editProfile: {
      authority:
        'https://nextervwell.b2clogin.com/nextervwell.onmicrosoft.com/B2C_1A_SNWEB_ProfileEdit'
    },
    changePassword: {
      authorities:
        'https://nextervwell.b2clogin.com/nextervwell.onmicrosoft.com/B2C_1A_SNWEB_PasswordChange'
    }
  },
  authorityDomain: 'nextervwell.b2clogin.com'
}

const apiConfig = {
  b2cScopes: [
    'https://nextervwell.onmicrosoft.com/b73e5d82-ac7c-46fa-af60-9f2c23882c4b/read'
  ],
  webApi: 'https://fabrikamb2chello.azurewebsites.net/hello'
}

const msalConfig = {
  auth: {
    clientId: 'b73e5d82-ac7c-46fa-af60-9f2c23882c4b', // 2fdd06f3-7b34-49a3-a78b-0cf1dd87878e// This is the ONLY mandatory field everything else is optional.
    authority: b2cPolicies.authorities.signUpSignIn.authority, // Choose sign-up/sign-in user-flow as your default.
    knownAuthorities: [b2cPolicies.authorityDomain], // You must identify your tenant's domain as a known authority.
    redirectUri: process.env.VUE_APP_AD_URL // You must register this URI on Azure Portal/App Registration. Defaults to 'window.location.href'.
  },
  cache: {
    cacheLocation: 'sessionStorage', // Configures cache location. 'sessionStorage' is more secure, but 'localStorage' gives you SSO.
    storeAuthStateInCookie: isIE || isEdge // If you wish to store cache items in cookies as well as browser cache, set this to 'true'.
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return
        }
        switch (level) {
          case Msal.LogLevel.Error:
            console.error(message)
            return
          case Msal.LogLevel.Info:
            // console.info(message)
            return
          case Msal.LogLevel.Verbose:
            // console.debug(message)
            return
          case Msal.LogLevel.Warning:
            console.warn(message)
        }
      }
    }
  }
}

/**
 *  Vue Instance Definition
 */

let instance

export const getInstance = () => instance

/**
 *  Vue Instance Initialization
 */

export const useB2C = ({ onRedirectCallback = () => {} }) => {
  if (instance) return instance

  instance = new Vue({
    data () {
      return {
        b2cClient: null,
        isLoading: true,
        isAuthenticated: false,
        accessToken: null,
        accoutId: '',
        user: {},
        error: null,
        claim: null,
        role: null,
        isSiteAdmin: false,
        isTenantAdmin: false
      }
    },
    methods: {
      async getToken () {
        return await this.b2cClient
          .acquireTokenSilent({
            scopes: [...apiConfig.b2cScopes],
            account: this.user.account
          })
          .catch(async error => {
            console.log('silent token acquisition fails.')
            if (error instanceof Msal.InteractionRequiredAuthError) {
              // fallback to interaction when silent call fails
              console.log('acquiring token using redirect')
              this.b2cClient.acquireTokenRedirect({
                scopes: [...apiConfig.b2cScopes]
              })
            } else {
              console.error(error)
            }
          })
      },
      changePassword () {
        this.b2cClient.loginPopup({
          authority: b2cPolicies.authorities.changePassword.authority
          // scopes: [...apiConfig.b2cScopes]
        })
      },
      editProfile () {
        this.b2cClient.loginRedirect({
          authority: b2cPolicies.authorities.editProfile.authority
          // scopes: [...apiConfig.b2cScopes]
        })
      },
      logout () {
        this.isAuthenticated = false
        const currentAcc = this.b2cClient.getAccountByHomeId(this.accountId)
        sessionStorage.clear()
        this.b2cClient.logout(currentAcc)
      },
      parseJwt (token) {
        var base64Url = token.split('.')[1]
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
        var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
        }).join(''))
        return JSON.parse(jsonPayload)
      },
      validateRoleCondition (role) {
        if (role && role.length === 0) router.push({ name: 'notauthorized' })
        // Parse data
        this.role = JSON.parse(role[0])
        // console.log(this.role)
        this.role.service = this.role.service.filter(val => val.service === 'webScreeningService')
        // Check tenant
        if (this.role.service[0].tenant && !Array.isArray(this.role.service[0].tenant)) this.logout()
        // console.log(this.role)
        // console.log(this.role.service[0].tenant)
        this.isAuthenticated = true
        // Check term and condition
        if (!this.role.consent || !this.role.consent.termCondition || !this.role.consent.termCondition.isAccept) router.push({ name: 'termcondition' })
        // Check PN
        if (!this.role.consent || !this.role.consent.privacyPolicy || !this.role.consent.privacyPolicy.isAccept) router.push({ name: 'privacynotice' })
        // this.isLoading = false
        this.validateTenant()
        // onRedirectCallback()
      },
      validateTenant () {
        if (this.role) {
          if (this.role.service[0].tenant.length === 1) {
            sessionStorage.setItem('Tenant', JSON.stringify(this.role.service[0].tenant[0]))
            this.isTenantAdmin = this.role.service[0].tenant[0].isTenantAdmin
            this.validateSite()
          } else {
            console.log('select tenant')
            router.push({ name: 'selecttenant' })
          }
        }
      },
      validateSite () {
        const selectedTanent = JSON.parse(sessionStorage.getItem('Tenant'))
        // console.log(selectedTanent)
        // const index = this.role.tenant.filter(data => data.tenantId === selectedTanent.tenantId)
        if (selectedTanent.site) {
          if (selectedTanent.site.length === 1) {
            // Only one site
            sessionStorage.setItem('Site', JSON.stringify(selectedTanent.site[0]))
            this.isSiteAdmin = selectedTanent.site[0].isSiteAdmin
            this.validatePage()
          } else if (selectedTanent.site.length > 1) {
            // More than one site
            const currentSite = sessionStorage.getItem('Site')
            if (currentSite) {
              // Has current site
              // if (this.role.site.filter(data => data.siteId === JSON.parse(currentSite).siteId).length > 0) {
              // console.log(selectedTanent)
              if (selectedTanent.site.filter(data => data.siteId === JSON.parse(currentSite).siteId).length > 0) {
                // validate permission true
                sessionStorage.setItem('Site', JSON.stringify(selectedTanent.site.filter(data => data.siteId === JSON.parse(currentSite).siteId)[0]))
                this.isSiteAdmin = selectedTanent.site.filter(data => data.siteId === JSON.parse(currentSite).siteId)[0].isSiteAdmin
                this.validatePage()
              } else {
                router.push({ name: 'selectsite' })
                // validate permission false and go to select site page
              }
            } else {
              router.push({ name: 'selectsite' })
              // go to select site page
            }
          } else {
            if (this.role.isSystemAdmin || this.isTenantAdmin) {
              router.push({ name: 'selectsite' })
            } else {
              router.push({ name: 'createnewsite' })
            }
          }
        } else {
          // Unavailable site
          if (this.role.isSystemAdmin || this.isTenantAdmin) {
            router.push({ name: 'selectsite' })
          } else {
            router.push({ name: 'createnewsite' })
          }
        }
      },
      validatePage () {
        const lastRouteName = sessionStorage.getItem('LS_ROUTE_KEY')
        const lastRouteParams = sessionStorage.getItem('LS_ROUTE_PARAMS')
        const selectedTanent = JSON.parse(sessionStorage.getItem('Tenant'))
        const selectedSite = JSON.parse(sessionStorage.getItem('Site'))
        console.log('validatePage')
        initChat({ tenant: selectedTanent, site: selectedSite })
        if (lastRouteName) {
          router.push({ name: lastRouteName, params: JSON.parse(lastRouteParams) }).catch(failure => {
            if (isNavigationFailure(failure, NavigationFailureType.duplicated)) {
              router.push({ name: 'general' })
            }
          })
        } else {
          router.push({ name: 'general' })
        }
      }
    },
    async created () {
      this.b2cClient = new Msal.PublicClientApplication(msalConfig)
      // this.isLoading = true
      try {
        let tokenResponse = await this.b2cClient.handleRedirectPromise()
        let accountObj
        if (tokenResponse) {
          accountObj = tokenResponse.account
          this.accountId = tokenResponse.account.homeAccountId
          this.accessToken = tokenResponse.accessToken
          this.user = tokenResponse
        } else {
          accountObj = this.b2cClient.getAllAccounts()[0]
        }
        store.dispatch('clearChatThreadLists')
        if (accountObj && tokenResponse) {
          console.log(
            '[AuthService.init] Got valid accountObj and tokenResponse'
          )
          this.accessToken = tokenResponse.accessToken
          this.accountId = tokenResponse.account.homeAccountId
          this.user = tokenResponse
          this.claim = this.parseJwt(tokenResponse.accessToken)
          // onRedirectCallback(accountObj)
          this.validateRoleCondition(this.claim.role)
        } else if (accountObj) {
          // try {
          tokenResponse = await this.b2cClient.acquireTokenRedirect({
            scopes: [...apiConfig.b2cScopes]
          })
          accountObj = tokenResponse.account
          this.accessToken = tokenResponse.accessToken
          this.accountId = tokenResponse.account.homeAccountId
          this.user = tokenResponse
          this.claim = this.parseJwt(tokenResponse.accessToken)
          this.validateRoleCondition(this.claim.role)
        } else {
          console.log(
            '[AuthService.init] No accountObject or tokenResponse present. User must now login.'
          )
          this.b2cClient.loginRedirect({
            authority: b2cPolicies.authorities.signUpSignIn.authority,
            scopes: [...apiConfig.b2cScopes]
          })
        }
      } catch (error) {
        if (error.errorMessage) {
          if (error.errorMessage.indexOf('AADB2C90118') > -1) {
            this.b2cClient.loginRedirect({
              authority: b2cPolicies.authorities.forgotPassword.authority,
              scopes: [...apiConfig.b2cScopes]
            })
          } else {
            this.b2cClient.loginRedirect({
              authority: b2cPolicies.authorities.signUpSignIn.authority,
              scopes: [...apiConfig.b2cScopes]
            })
          }
        }
      } finally {
        this.isLoading = false
      }
    }
  })

  return instance
}

/**
 *  Vue Plugin Definition
 */

export const B2CPlugin = {
  install (Vue, options) {
    Vue.prototype.$auth = useB2C(options)
  }
}
