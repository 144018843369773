<template>
  <div >
     <v-progress-linear v-if="!isAuthen" indeterminate color='primary' />
     <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
  computed: {
    isAuthen () {
      return this.$auth.isLoading
    }
  }
}
</script>
