import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import i18n from './i18n'
import { B2CPlugin } from '@/plugins/adb2c-plugin'
// import Dayjs from 'vue-dayjs'
import '@/plugins/dayjs'
import 'dayjs/locale/th'
import '@fontsource/kanit'
import './registerServiceWorker'
import advancedFormat from 'dayjs/plugin/buddhistEra'
import VueAzureMaps from 'vue-azure-maps'
import VueGtag from 'vue-gtag'

Vue.use(VueAzureMaps, {
  // Get an Azure Maps key at https://azure.com/maps
  key: 'u046YRw7CJgjkCzTHqwwHylGSKP-PtY91QcIn-zyD_4'
})

Vue.config.productionTip = false

Vue.use(VueGtag, {
  config: { id: 'UA-196495947-1' },
  appName: 'VWELLSCREENNING',
  pageTrackerScreenviewEnabled: true
}, router)

Vue.use(B2CPlugin, {
  onRedirectCallback: (accountObj) => {
  }
})

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App),
  mounted () {
    this.$dayjs.extend(advancedFormat)
    this.$gtag.set({
      cookie_flags: 'SameSite=None;Secure'
    })
  }
}).$mount('#app')
