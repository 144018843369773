// import Vue from 'vue'
import {
  ChatClient
} from '@azure/communication-chat'
import {
  AzureCommunicationTokenCredential,
  isCommunicationUserIdentifier
} from '@azure/communication-common'
import { CallClient } from '@azure/communication-calling'
import { EventBus } from '@/plugins/event-bus'
import { getSitesPromise, patchSitePromise, getChatThread } from '@/api/'
import store from '@/store'

// Project configurations
export const INITIAL_MESSAGES_SIZE = 2000
export const MAXIMUM_LENGTH_OF_NAME = 10
export const MAXIMUM_LENGTH_OF_MESSAGE = 8000
export const MAXIMUM_LENGTH_OF_TOPIC = 30
export const MAXIMUM_LENGTH_OF_TYPING_USERS = 35
export const MINIMUM_TYPING_INTERVAL_IN_MILLISECONDS = 8000
export const MAXIMUM_INT64 = 9223372036854775807
export const NUMBER_OF_MESSAGES_TO_LOAD = 10
export const PAGE_SIZE = 200
export const PARTICIPANTS_THRESHOLD = 20

const communicationEndpoint = 'https://comservicevwell.communication.azure.com'

const initChat = async ({ tenant, site }) => {
  console.log('initChat')
  try {
    const userToken = await getToken({ tenantId: tenant.tenantId, siteId: site.siteId })
    const options = {
      token: userToken.token,
      tokenRefresher: () => refreshTokenAsync({ tenantId: tenant.tenantId, siteId: site.siteId }),
      refreshProactively: true
    }
    const tokenCredential = new AzureCommunicationTokenCredential(options)
    const chatClient = new ChatClient(communicationEndpoint, tokenCredential)
    window.communicationService = userToken
    window.chatClient = chatClient
    window.tokenCredential = tokenCredential
    await subscribeForMessage(chatClient)
    await subscribeForTypingIndicator(chatClient)
    await subscribeForReadReceipt(chatClient)
    await subscribeForChatParticipants(chatClient)
    await subscribeForChatThreadCreated(chatClient)
    store.dispatch('clearChatThreadLists')
    const thread = await getChatThread({ tenantId: tenant.tenantId, siteId: site.siteId, cid: '', offset: 0, limit: 100 })
    const allThreads = await listChatThread(chatClient)
    const threadsData = thread.data.result.items.filter(el => el.chatThread)
    const threadLists = []
    threadsData.forEach(element => {
      allThreads.forEach(subElement => {
        if (element.chatThread === subElement.id) {
          threadLists.push({ ...element, ...subElement })
        }
      })
    })
    // threadLists.reverse()
    threadLists.sort(function (a, b) {
      var keyA = new Date(a.lastMessageReceivedOn).getTime()
      var keyB = new Date(b.lastMessageReceivedOn).getTime()
      // Compare the 2 dates
      if (keyA < keyB) return 1
      if (keyA > keyB) return -1
      return 0
    })
    store.dispatch('SetChatThreadLists', threadLists)
    // const callClient = new CallClient()
    // const callAgent = await callClient.createCallAgent(tokenCredential)
    // window.callAgent = callAgent
    // await subscribeForIncomingCall(callAgent)
  } catch (error) {
    console.log('communication service error')
    console.log(error)
  }
}

const initCall = async ({ site }) => {
  console.log('initChat')
  try {
    if (window.callAgent) return // alrady init call agent
    if (!window.tokenCredential) { // check credential
      console.log('Token Credential not found')
      const userToken = await getToken()
      const options = {
        token: userToken.token,
        tokenRefresher: () => refreshTokenAsync(),
        refreshProactively: true
      }
      const tokenCredential = new AzureCommunicationTokenCredential(options)
      window.tokenCredential = tokenCredential
    }
    const callClient = new CallClient()
    console.log(site.siteName)
    const callAgent = await callClient.createCallAgent(window.tokenCredential, { displayName: site.siteName })
    const deviceManager = await callClient.getDeviceManager()
    window.callAgent = callAgent
    window.deviceManager = deviceManager
    await deviceManager.askDevicePermission({ audio: true })
    await deviceManager.askDevicePermission({ video: true })
    await subscribeForIncomingCall(callAgent)
    await subscribeForCallsUpdated(callAgent)
  } catch (error) {
    console.log('communication service error')
    console.log(error)
  }
}

// const updateThreadLists = async ({ tenant, site, chatClient }) => {
//   const thread = await getChatThread({ tenantId: tenant.tenantId, siteId: site.siteId, cid: '', offset: 0, limit: 100 })
//   const allThreads = await listChatThread(chatClient)
//   const threadsData = thread.data.result.items.filter(el => el.chatThread)
//   const threadLists = []
//   threadsData.forEach(element => {
//     allThreads.forEach(subElement => {
//       if (element.chatThread === subElement.id) {
//         threadLists.push({ ...element, ...subElement })
//       }
//     })
//   })
//   threadLists.reverse()
//   store.dispatch('SetChatThreadLists', threadLists)
// }

const listChatThread = async (chatClient) => {
  const threads = chatClient.listChatThreads({ maxPageSize: PAGE_SIZE })
  const allthreads = []
  for await (const page of threads.byPage()) {
    for (const thread of page) {
      allthreads.push(thread)
    }
  }
  return allthreads
}
const subscribeForCallsUpdated = async (callAgent) => {
  callAgent.on('callsUpdated', async (event) => {
    console.log('callsUpdated')
    console.log(event)
    EventBus.$emit('callsUpdated', event)
  })
}

const subscribeForMessage = async (chatClient) => {
  await chatClient.startRealtimeNotifications()
  chatClient.on('chatMessageReceived', async (event) => {
    EventBus.$emit('chatMessageReceived', event)
  })
}

const subscribeForTypingIndicator = async (chatClient) => {
  await chatClient.startRealtimeNotifications()
  chatClient.on('typingIndicatorReceived', async (event) => {
    EventBus.$emit('typingIndicatorReceived', event)
  })
}

const subscribeForReadReceipt = async (chatClient) => {
  await chatClient.startRealtimeNotifications()
  chatClient.on('readReceiptReceived', async (event) => {
    EventBus.$emit('readReceiptReceived', event)
  })
}

const subscribeForChatParticipants = async (chatClient) => {
  await chatClient.startRealtimeNotifications()
  chatClient.on('participantsRemoved', async (event) => {
    EventBus.$emit('participantsRemoved', event)
  })
  chatClient.on('participantsAdded', async (event) => {
    EventBus.$emit('participantsAdded', event)
  })
}

const subscribeForIncomingCall = async (callAgent) => {
  callAgent.on('incomingCall', async (event) => {
    EventBus.$emit('incomingCall', event)
  })
}

const subscribeForChatThreadCreated = async (chatClient) => {
  await chatClient.startRealtimeNotifications()
  chatClient.on('chatThreadCreated', async (event) => {
    EventBus.$emit('chatThreadCreated', event)
  })
}

const getToken = async ({ tenantId, siteId }) => {
  try {
    const siteInfo = await getSitesPromise({ tenantId: tenantId, siteId: siteId })
    if (siteInfo.data.code === 1) {
      const tokenInfo = await patchSitePromise({ tenantId: tenantId, siteId: siteId, id: siteInfo.data.result.items[0].id, etag: siteInfo.data.result.items[0]._etag }, {})
      if (tokenInfo.data.code === 1) {
        return tokenInfo.data.result.communicationService
      }
    }
  } catch (error) {
    console.log(error)
  }
  return null
}

const refreshTokenAsync = async ({ tenantId, siteId }) => {
  try {
    const siteInfo = await getSitesPromise({ tenantId: tenantId, siteId: siteId })
    if (siteInfo.data.code === 1) {
      const tokenInfo = await patchSitePromise({ tenantId: tenantId, siteId: siteId, id: siteInfo.data.result.items[0].id, etag: siteInfo.data.result.items[0]._etag }, {})
      if (tokenInfo.data.code === 1) {
        return tokenInfo.data.result.communicationService.token
      }
    }
  } catch (error) {
    console.log(error)
  }
  return null
}

const isUserMatchingIdentity = (user, communicationUserId) => {
  return isCommunicationUserIdentifier(user) && user.communicationUserId === communicationUserId
}

export {
  initChat,
  initCall,
  isUserMatchingIdentity
}
