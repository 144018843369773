import Vue from 'vue'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import buddhistEra from 'dayjs/plugin/buddhistEra'
import localeTh from 'dayjs/locale/th'
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)
dayjs.extend(buddhistEra)
dayjs.extend(relativeTime)
// dayjs.extend(locale_th);
console.log(localeTh)
Object.defineProperties(Vue.prototype, {
  $dayjs: {
    get () {
      return dayjs
    }
  }
})
