import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import light from './vuetify/theme'
// Translation provided by Vuetify (javascript)
import th from 'vuetify/es5/locale/th'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    themes: {
      light: light
    },
    options: { customProperties: true }
  },
  icons: {
    iconfont: 'mdi' // default - only for display purposes
  },
  lang: {
    locales: { th },
    current: 'th'
  }
})
